import * as yup from 'yup';

export default yup.object().shape({
  name: yup
    .string()
    .trim()
    .matches(
      /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/,
      'não deve possuir números ou simbolos proibidos'
    )
    .required('Este campo é obrigatório.'),
  email: yup
    .string()
    .email('Email inválido')
    .required('Este campo é obrigatório.'),
  active: yup.string().required('Este campo é obrigatório.'),
  tag_id: yup.string().required('Este campo é obrigatório.'),
});
