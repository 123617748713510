import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  current: {
    name: '',
    active: false,
    affiliate_button: false,
    tags: [],
    kind: '',
    point_day_limit: 0,
    point_value: 0,
    point_conference_limit: 0,
    point_on_click: false,
    side_button: false,
    pdf_button: false,
    warmup_room: {
      list: [],
    },
    countdown: {
      end_time: new Date(),
    },
    question: {
      title: '',
      period: '',
      items: [],
    },
    launch_plan: {
      title: '',
      items: [],
    },
    resource: {
      title: '',
      items: [],
    },
    sale_letter: {
      cta_link: '',
      cta_text: '',
      central: false,
      icon: '',
      content: '',
      text_color: '#000000',
      background_color: '#cccc',
      icon_color: '#000000',
    },
    conference_button: {
      button_fallback_link: '',
      button_text: '',
    },
  },
  list: [],
  loading: false,
  error: '',
  success: '',
};

export const { Types, Creators } = createActions({
  componentCreateRequest: ['payload'],
  componentCreateSuccess: ['success'],
  componentCreateFailure: ['error'],
  componentUpdateRequest: ['payload'],
  componentUpdateSuccess: ['success'],
  componentUpdateFailure: ['error'],
  componentDeleteRequest: ['payload'],
  componentDeleteSuccess: ['success'],
  componentDeleteFailure: ['error'],
  fetchComponentKindData: [],
  resetComponent: [],
  setComponentData: ['payload'],
  setCurrentComponent: ['payload'],
  setComponentKindData: ['payload'],
  fetchAllComponentsRequest: ['payload'],
  fetchAllComponentsSuccess: ['components'],
  fetchAllComponentsFailure: ['error'],
  toggleComponent: ['componentId'],
});

function setCurrentComponent(state, { payload }) {
  return {
    ...state,
    current: { ...payload },
  };
}

function setComponentData(state, { payload }) {
  const currentComponent = { ...state.current };
  currentComponent[payload.name] = payload.value;

  return {
    ...state,
    current: currentComponent,
    loading: false,
  };
}

function setComponentKindData(state, { payload }) {
  const currentComponent = { ...state.current };
  const currentComponentKind = { ...currentComponent[payload.kind] };
  currentComponentKind[payload.name] = payload.value;
  currentComponent[payload.kind] = currentComponentKind;

  return {
    ...state,
    current: currentComponent,
  };
}

function resetComponent(state) {
  return { ...state, current: { ...INITIAL_STATE.current } };
}

function fetchComponentKindData(state) {
  return { ...state, loading: true };
}

function componentCreateRequest(state) {
  return { ...state, loading: true, error: '', success: '' };
}

function componentCreateSuccess(state, { success }) {
  return {
    ...state,
    loading: false,
    success,
  };
}

function componentCreateFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}
function fetchAllComponentsRequest(state) {
  return { ...state, loading: true };
}

function fetchAllComponentsSuccess(state, { components }) {
  return {
    ...state,
    loading: false,
    list: components,
  };
}

function fetchAllComponentsFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

function componentUpdateRequest(state) {
  return { ...state, loading: true, error: '', success: '' };
}

function componentUpdateSuccess(state, { success }) {
  return {
    ...state,
    loading: false,
    success,
  };
}

function componentUpdateFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

function componentDeleteRequest(state) {
  return { ...state, loading: true };
}

function componentDeleteSuccess(state, { success }) {
  return {
    ...state,
    loading: false,
    success,
  };
}

function componentDeleteFailure(state, { error }) {
  return {
    ...state,
    loading: false,
    error,
  };
}

function toggleComponent(state) {
  return {
    ...state,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.COMPONENT_CREATE_REQUEST]: componentCreateRequest,
  [Types.COMPONENT_CREATE_SUCCESS]: componentCreateSuccess,
  [Types.COMPONENT_CREATE_FAILURE]: componentCreateFailure,
  [Types.COMPONENT_UPDATE_REQUEST]: componentUpdateRequest,
  [Types.COMPONENT_UPDATE_SUCCESS]: componentUpdateSuccess,
  [Types.COMPONENT_UPDATE_FAILURE]: componentUpdateFailure,
  [Types.COMPONENT_DELETE_REQUEST]: componentDeleteRequest,
  [Types.COMPONENT_DELETE_SUCCESS]: componentDeleteSuccess,
  [Types.COMPONENT_DELETE_FAILURE]: componentDeleteFailure,
  [Types.FETCH_COMPONENT_KIND_DATA]: fetchComponentKindData,
  [Types.RESET_COMPONENT]: resetComponent,
  [Types.SET_COMPONENT_DATA]: setComponentData,
  [Types.SET_COMPONENT_KIND_DATA]: setComponentKindData,
  [Types.SET_CURRENT_COMPONENT]: setCurrentComponent,
  [Types.FETCH_ALL_COMPONENTS_REQUEST]: fetchAllComponentsRequest,
  [Types.FETCH_ALL_COMPONENTS_SUCCESS]: fetchAllComponentsSuccess,
  [Types.FETCH_ALL_COMPONENTS_FAILURE]: fetchAllComponentsFailure,
  [Types.TOGGLE_COMPONENT]: toggleComponent,
});
