import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Container,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core';

import { useFormik } from 'formik';
import userSchema from './user.schema';

import { Creators as TagsActions } from '~/store/modules/tag/ducks';
import { Creators as UserActions } from '~/store/modules/user/ducks';

export default function UserFrom({ userInfo, handleClose }) {
  const dispatch = useDispatch();
  const tagsList = useSelector((state) => state.tag.list);
  const createOrUpdate = userInfo.id ? 'Atualizar' : 'Adicionar';

  useEffect(() => {
    dispatch(TagsActions.tagListRequest());
  }, [dispatch]);

  const handleSubmit = (formValues) => {
    const formFields = formValues;
    formFields.close = handleClose;
    if (createOrUpdate === 'Atualizar') {
      formFields.id = userInfo.id;
      return dispatch(UserActions.userUpdateRequest(formValues));
    }
    return dispatch(UserActions.userCreateRequest(formValues));
  };

  const formik = useFormik({
    initialValues: {
      name: userInfo.name,
      email: userInfo.email,
      tag_id: userInfo.tag_id,
      active: userInfo.active,
    },
    validationSchema: userSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const hasError = (fieldName) =>
    formik.touched[fieldName] && !!formik.errors[fieldName];

  return (
    <Container maxWidth="xs">
      <Box mb={4}>
        <TextField
          name="name"
          variant="outlined"
          label="Nome"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={hasError('name')}
          onBlur={formik.handleBlur}
          helperText={hasError('name') ? formik.errors.name : null}
          fullWidth
        />
      </Box>
      <Box mb={4}>
        <TextField
          name="email"
          variant="outlined"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={hasError('email')}
          onBlur={formik.handleBlur}
          helperText={hasError('email') ? formik.errors.email : null}
          fullWidth
        />
      </Box>
      <Box mb={4}>
        <TextField
          select
          name="tag_id"
          label="Selecione a Tag"
          value={formik.values.tag_id}
          onChange={formik.handleChange}
          error={hasError('tag_id')}
          onBlur={formik.handleBlur}
          helperText={hasError('tag_id') ? formik.errors.tag_id : null}
          variant="outlined"
          fullWidth
        >
          {!!tagsList.length &&
            tagsList.map((tag) => (
              <MenuItem value={tag.id}>{tag.name}</MenuItem>
            ))}
        </TextField>
      </Box>
      <Box mb={4}>
        <FormControlLabel
          control={
            <Switch
              checked={formik.values.active}
              onChange={formik.handleChange}
              error={hasError('active')}
              onBlur={formik.handleBlur}
              helperText={hasError('active') ? formik.errors.active : null}
              name="active"
              color="primary"
            />
          }
          label="Ativo"
        />
      </Box>
      <Box mb={4}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          onClick={formik.handleSubmit}
        >
          {createOrUpdate}
        </Button>
      </Box>
    </Container>
  );
}

UserFrom.defaultProps = {
  userInfo: {
    name: '',
    email: '',
    tag_id: 1,
    active: true,
  },
};

UserFrom.propTypes = {
  handleClose: PropTypes.func.isRequired,
  userInfo: PropTypes.objectOf({
    name: PropTypes.string,
    email: PropTypes.string,
    tag_id: PropTypes.number,
    active: PropTypes.bool,
  }),
};
